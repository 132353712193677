export const environment = {
  production: false,
  serverUrl: 'https://staging.api.stellar.online/api/',
  casUrl: 'https://staging.api.stellar.online/cas/',
  productId: '1ec4a3a3-6223-486c-a264-0a778895b295',
  serverName: 'Stellar Registration',
  subServerName: 'Stellar',
  profileImageURL: '',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee76348d3123a0ef0d8cb63',// Viewer
  },
  trackingUrl: 'https://staging.track.stellar.online',
};
